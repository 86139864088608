import React from "react"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import { getRoute, ROUTES_ID } from "../constants/routes.contants"

const Confidentialite = () => {
  const route = getRoute(ROUTES_ID.CONFIDENTIALITE)
  return (
    <>
      <Seo title={route.screenTitle} />
      <Layout className="user-informations">
        <h2>Politique de confidentialité</h2>
        <h3>Qui suis-je ?</h3>
        <p>
          Je suis Sébastion Outreville (accompagnement et conseils en
          investissement) – Propriétaire du site web https://www.aci-conseils.fr
        </p>
        <h3>Utilisation des données personnelles collectées</h3>
        <h4>Commentaires</h4>
        <p>
          Quand vous laissez un commentaire sur mon site web, les données
          inscrites dans le formulaire de commentaire, mais aussi votre adresse
          IP et l’agent utilisateur de votre navigateur sont collectés pour nous
          aider à la détection des commentaires indésirables.
          <br />
          Une chaîne anonymisée créée à partir de votre adresse de messagerie
          (également appelée hash) peut être envoyée au service Gravatar pour
          vérifier si vous utilisez ce dernier. Les clauses de confidentialité
          du service Gravatar sont disponibles ici :
          https://automattic.com/privacy/. Après validation de votre
          commentaire, votre photo de profil sera visible publiquement à coté de
          votre commentaire.
        </p>
        <h4>Médias</h4>
        <p>
          Si vous êtes un utilisateur ou une utilisatrice enregistré·e et que
          vous téléversez des images sur le site web, nous vous conseillons
          d’éviter de téléverser des images contenant des données EXIF de
          coordonnées GPS. Les visiteurs de votre site web peuvent télécharger
          et extraire des données de localisation depuis ces images.
        </p>
        <h4>Formulaires de contact</h4>
        <p>
          L’adresse mail utilisée dans l’onglet « Contact » servira uniquement à
          répondre à vos questions à propos des services proposés. Celle-ci ne
          sera pas collectée sans votre autorisation et nous vous assurons de
          garder la confidentialité de vos données personnelles.
        </p>
        <h4>E-mails & Newsletters</h4>
        <p>
          Votre adresse mail est collectée pour vous envoyer des articles basés
          sur la gestion de patrimoine. Aussi, ces newsletters ont pour but de
          vous informer des prestations de services proposés sur le blog
          www.aci-conseils.fr.
          <br />
          Votre adresse mail ne sera jamais collectée pour être livrée à un
          tiers.
          <br />
          Vous pouvez vous désinscrire à tout moment en client sur le lien « me
          désabonner » dans les mails provenant de aci-conseils qui vous ont été
          envoyé.
        </p>
        <h4>Cookies</h4>
        <p>
          Si vous déposez un commentaire ou demandez des informations sur mon
          site, il vous sera proposé d’enregistrer votre nom, prénom, adresse de
          messagerie et site web dans des cookies. C’est uniquement pour votre
          confort afin de ne pas avoir à saisir ces informations si vous déposez
          un autre commentaire plus tard. Ces cookies expirent au bout d’un an.
          <br />
          Si vous vous rendez sur la page de connexion, un cookie temporaire
          sera créé afin de déterminer si votre navigateur accepte les cookies.
          Il ne contient pas de données personnelles et sera supprimé
          automatiquement à la fermeture de votre navigateur.
          <br />
          Lorsque vous vous connecterez, nous mettrons en place un certain
          nombre de cookies pour enregistrer vos informations de connexion et
          vos préférences d’écran. La durée de vie d’un cookie de connexion est
          de deux jours, celle d’un cookie d’option d’écran est d’un an. Si vous
          cochez « Se souvenir de moi », votre cookie de connexion sera conservé
          pendant deux semaines. Si vous vous déconnectez de votre compte, le
          cookie de connexion sera effacé.
          <br />
          En modifiant ou en publiant une publication, un cookie supplémentaire
          sera enregistré dans votre navigateur. Ce cookie ne comprend aucune
          donnée personnelle. Il indique simplement l’ID de la publication que
          vous venez de modifier. Il expire au bout d’un jour.
        </p>
        <h3>Contenu embarqué depuis d’autres sites</h3>
        <p>
          Les articles de ce site peuvent inclure des contenus intégrés (par
          exemple des vidéos, images, articles…). Le contenu intégré depuis
          d’autres sites se comporte de la même manière que si le visiteur se
          rendait sur cet autre site.
          <br />
          Ces sites web pourraient collecter des données sur vous, utiliser des
          cookies, embarquer des outils de suivis tiers, suivre vos interactions
          avec ces contenus embarqués si vous disposez d’un compte connecté sur
          leur site web.
        </p>
        <h3>Statistiques et mesures d’audience</h3>
        <p>
          Afin d’analyser l’audience du site web www.aci-conseils.fr, nous
          utilisons l’outil Google analytics. Vous pouvez consulter leur
          politique de confidentialité sur le lien suivant :
          https://www.google.com/analytics/learn/privacy.html?hl=fr.
        </p>
        <h3>Utilisation et transmission de vos données personnelles</h3>
        <p>
          Durées de stockage de vos données
          <br />
          Si vous laissez un commentaire, le commentaire et ses métadonnées sont
          conservés indéfiniment. Cela permet de reconnaître et approuver
          automatiquement les commentaires suivants au lieu de les laisser dans
          la file de modération.
          <br />
          Pour les utilisateurs et utilisatrices qui s’enregistrent sur notre
          site (si cela est possible), nous stockons également les données
          personnelles indiquées dans leur profil. Tous les utilisateurs et
          utilisatrices peuvent voir, modifier ou supprimer leurs informations
          personnelles à tout moment (à l’exception de leur nom
          d’utilisateur·ice). Les gestionnaires du site peuvent aussi voir et
          modifier ces informations.
          <br />
          Les droits que vous avez sur vos données
          <br />
          Si vous avez un compte ou si vous avez laissé des commentaires sur le
          site, vous pouvez demander à recevoir un fichier contenant toutes les
          données personnelles que nous possédons à votre sujet, incluant celles
          que vous nous avez fournies. Vous pouvez également demander la
          suppression des données personnelles vous concernant. Cela ne prend
          pas en compte les données stockées à des fins administratives, légales
          ou pour des raisons de sécurité.
        </p>
        <h3>Transmission de vos données personnelles</h3>
        <p>
          Les commentaires des visiteurs peuvent être vérifiés à l’aide d’un
          service automatisé de détection des commentaires indésirables.
          <br />
          Informations de contact
          <br />
          Informations supplémentaires
          <br />
          En cas de renseignement vous pouvez nous joindre à l’adresse mail
          suivante : outreville@gmail.com
        </p>
        <h3>Comment nous protégeons vos données</h3>
        <p>
          Le site web https://www.aci-conseils.fr est protégé par le chiffrement
          SSL. Nous utilisons le protocole HTTPS. Nous procédons aux mises à
          jour régulières de WordPress. Une alerte est envoyée à
          l’administrateur en cas d’anomalies et de dysfonctionnement. Enfin,
          nous utilisons des mots de passe « longs et forts ».
        </p>
        <h4>Procédures mises en œuvre en cas de fuite de données</h4>
        <p>
          Si nous détectons des fuites de données, vous serez avertis par mail
          dans les meilleurs délais si vous êtes un utilisateur enregistré dans
          notre base de données ou si vous avez laissé un commentaire sur ce
          site. Nous publierons également une communication directement sur le
          site à l’attention de tous.
          <br />
          Affichage des informations liées aux secteurs soumis à des régulations
          spécifiques
          <br />
          Informations légales :<br />
          Enregistré à l’ORIAS sous le numéro 18007103 (orias.fr)
          <br />
          Mandataire d’Intermédiaire en Assurance
          <br />
          Mandataire d’Intermédiaire en Opérations de Banque et Services de
          Paiement
          <br />
          Agent Lié de Prestataires de Services d’Investissements
          <br />
          Sous le contrôle de l’Autorité de Contrôle Prudentiel et de
          Résolution, 4 Place de Budapest, 75436 Paris Cedex 9<br />
        </p>
      </Layout>
    </>
  )
}

export default Confidentialite
